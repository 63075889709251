<template>
    <div class="-mt-6 border-t border-blue-200 -mx-6 px-6 md:pr-0 md:-mx-10 md:pl-6 xl:pl-10">
        
        <div class="flex flex-row flex-wrap border-b border-blue-200">
            <div class="w-full lg:w-8/12 flex flex-col pr-10 py-6">


                <div class="pb-12">

                    <div class="flex flex-row items-center mb-10">
                        <div class="w-2/5 text-xl font-bold">
                            Account Summary
                        </div>
                        <!-- <div class="w-2/5 flex flow-row justify-center">
                            <button class="border border-blue-500 rounded-sm p-2 text-xs text-blue-500 lg:mr-2" @click="chartType = 'line' ">Line Chart</button>
                            <button  class="border border-blue-500 rounded-sm p-2 text-xs text-blue-500" @click="chartType = 'bar' ">Bar Chart</button>
                        </div> -->
                        <custom-select
                            :options="chartTypeOptions"
                            v-model="chartType"
                            class="1/5 custom-select border border-blue-500 rounded-sm pl-3 pr-6 py-2 text-xs text-blue-500 relative ml-auto"
                        />
                        <custom-select
                            :options="chartOptions"
                            v-model="chartRange"
                            class="1/5 custom-select border border-blue-500 rounded-sm pl-3 pr-6 py-2 text-xs text-blue-500 relative ml-3"
                        />
                    </div>
                    
                    <div class="w-full flex justify-center items-center" style="height: 300px" v-if="transactionSummary.loading">
                        <p class="text-sm text-center text-teal">Loading...</p>
                    </div>
                    <custom-bar-chart
                        height="300"
                        :columns="barChartColumns"
                        :grid="[5, 0]"
                        :grid-x-label-format="gridXLabelFormat"
                        :maxValue="maxValue"
                        :tooltipFormat="tooltipFormat"
                        :valueFormat="valueFormat"
                        v-else-if="chartType == 'bar'"
                    />
                </div>

                <div class="pb-12">

                    <div class="flex items-center justify-between mb-6">
                        <h3 class="text-xl font-bold mr-2">Recent transactions</h3>
                        <router-link :to="{ name: 'payments' }" class="button px-4 py-2 border border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white">
                            Generate Report
                        </router-link>
                    </div>

                    <datatable
                        :data="transactions.data"
                        :columns="transactions.columns"
                        :actions="transactions.actions"
                        :limit="10"
                        :loading="transactions.loading"
                        :fillable="false"
                        :dropdown="false"
                        v-if="transactions.data.length || transactions.loading"
                    />
                    <div class="pt-6 pb-32 text-sm opacity-50" v-else>
                        <p>You have no active loan at the moment.</p>
                    </div>

                </div>

            </div>
            <div class="w-full lg:w-4/12 border-l border-blue-200 border-r md:border-r-0 flex flex-col p-10 pt-1">
                <div class="lg:w-full w-5/12 p-0 m-0">
                    <tabs :tabs="tabs" v-model="tab" />
                    <div class="w-full h-64 flex justify-center items-center" v-if="transactions.loading">
                        <p class="text-xs text-center text-teal">Loading...</p>
                    </div>
                    <span v-else>
                        <div class="w-full p-0 m-0" v-if="tab == 'credit'">
                            <doughnut-chart
                                id="debitChart"
                                :data="pieChart.data"
                                :colors="pieChart.colors"
                            />
                        </div>
                        <div class="w-full p-0 m-0" v-if="tab == 'debit'">
                            <doughnut-chart
                                id="creditChart"
                                :data="pieChart.data"
                                :colors="pieChart.colors"
                            />
                        </div>
                    </span>
                </div>

                <div class="lg:w-full  w-6/12 flex items-center flex-wrap mb-6 border-t border-blue-200 pt-5">
                    <h3 class="w-full text-xl font-bold mr-2 pb-5">Top transactions</h3>
                    <div class="w-full h-64 flex justify-center items-center" v-if="topTransactions.loading">
                        <p class="text-sm text-center text-teal">Loading...</p>
                    </div>
                    <div class="w-full" v-else>
                        <template v-for="(transaction, n) in topTransactions.data">
                            <div class="w-full flex flex-row border-t border-blue-200 py-3" :key="n">
                                <div class="w-7/12 text-xs text-start">
                                    <p class="text-gray-900 text-overflow-ellipse whitespace-no-wrap">
                                        {{ transaction.description }}
                                    </p>
                                    <p class="text-ash-400 text-3xs text-overflow-ellipse">
                                        {{ transaction.created_at | dateFormat('M dd') }} 
                                        at 
                                        {{ transaction.created_at | dateFormat('h:iA') }}
                                    </p>
                                </div>
                                <div class="w-5/12 text-xs text-right">
                                    <p class="text-gray-900">- ₦ {{ transaction.amount | currency }}</p>
                                    <div class="flex flex-row flex-wrap justify-end"> 
                                        <p>
                                            <!-- <span :style="getColor(transaction.department)"></span> -->
                                            <span class="text-ash-400 text-3xs">{{ transaction.department }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <modal className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-24 px-24 text-center" ref="showBarContent">
            <h1 class="text-md">Payments Preview</h1>
            <div class="p-4">
                <div class="w-full h-auto flex items-start flex-wrap mb-6 pb-0">
                    <div class="w-full text-start">
                        <div class="flex flex-row"> 
                            <p class="w-3/5 font-bold text-sm text-right">Department</p>
                            <p class="w-2/5 text-gray-700 text-sm text-left pl-5">{{modal.data.department}}</p>
                        </div>
                        <div class="flex flex-row" v-if="!modal.data.total">
                            <p class="w-3/5 font-bold text-sm text-right">Payment Type</p>
                            <p class="w-2/5 text-gray-700 text-sm text-left pl-5">{{modal.data.payable_type}}</p>
                        </div>
                        <div class="flex flex-row" v-if="!modal.data.total">
                            <p class="w-3/5 font-bold text-sm text-right">Payment Method</p>
                            <p class="w-2/5 text-gray-700 text-sm text-left pl-5">{{modal.data.payment_method}}</p>
                        </div>
                        <div class="flex flex-row" v-if="!modal.data.total">
                            <p class="w-3/5 font-bold text-sm text-right">Amount </p>
                            <p class="w-2/5 text-gray-700 text-sm text-left pl-5">₦{{ modal.data.amount | currency }}</p>
                        </div>
                        <div class="flex flex-row" v-if="modal.data.total">
                            <p class="w-3/5 font-bold text-sm text-right">Total Amount Spent</p>
                            <p class="w-2/5 text-gray-700 text-sm text-left pl-5">₦{{ modal.data.total | currency }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

    </div>
</template>

<script>
    // import dummy from '../../../../../dummy';
    export default {
        data() {
            return {
                // data: dummy.payments,
                count: 0,
                // showModal: false,
                chartType: 'bar',
                pieChart: this.$options.resource(
                    [
                        {label: 'Technology', value: 20},
                        {label: 'Facility Mgt', value: 20},
                        {label: 'Procurement', value: 20},
                        {label: 'Sales', value: 20},
                        {label: 'Account', value: 20}
                    ],
                    {
                        colors: ['#24398C', '#8FE8C3', '#FF95AF','#C3ADF2', '#93A7F6'],
                    }
                ),
                chartData: [],
                modal: {
                    data: '',
                },
                chartTypeOptions: [
                    { value: 'bar', title: 'Bar Chart' },
                    { value: 'line', title: 'Line Chart' },
                ],
                chartOptions: [
                    { value: 'today', title: 'Today' },
                    { value: 'this_week', title: 'This Week' },
                    { value: 'this_month', title: 'This Month' },
                ],
                chartRange: 'this_week',
                loading: false,
                tab: 'debit',
                tabs: [
                    { name: 'debit', title: 'Debit Analysis' },
                    { name: 'credit', title: 'Credit Analysis' },
                ],
                topTransactions: this.$options.resource([]),
                transactionSummary: this.$options.resource([]),
                transactions: this.$options.resource([], {
                    actions: [
                        {
                            text: 'Request Receipt',
                            class: 'btn btn-blue-soft btn-sm',
                            show: () => 0
                        },
                        {
                            text: '<ion-icon name="eye-outline" class="text-lg mr-2"></ion-icon> View Receipt',
                            class: 'bg-blue-100 text-blue-500 px-4 py-2 rounded-sm inline-flex items-center hover:bg-blue-500 hover:text-white',
                            action: this.viewReceipt,
                            show: () => 1,
                        }
                    ],
                    columns: [
                        {
                            name: 'description',
                            th: 'Description',
                        },
                        {
                            name: 'created_at',
                            th: 'Date',
                            render: (transaction, date) => {
                                const a = this.$options.filters.dateFormat;
                                return `${a(date, 'M dd')} at ${a(date, 'h:ia')}`;
                            }
                        },
                        {
                            name: 'status',
                            th: 'Status',
                            render: () => {
                                return `<div class="badge badge-blue-soft-outline">
                                    Pending
                                </div>`;
                            }
                        },
                        {
                            name: 'amount',
                            th: 'Amount',
                            render: (transaction, amount) => this.$options.filters.currency(amount)
                        },
                    ],
                }),
            }
        },
        computed: {
            maxValue(){
                var max = 0;
                this.barChartColumns.forEach(column => {
                    max = column.value > max ? column.value : max;
                });

                return max;
            },
            barChartColumns() {

                console.log(this.chartRange)

                var filteredData = this.transactionSummary.data;

                switch (this.chartRange) {
                    case 'today':
                        // filteredData.push({
                        //     label: `${begin.format('H:i')} - ${end.format('H:i')}`,
                        //     value: items.reduce((last, item) => last + Number(item.amount), 0)
                        // });
                        break;
                    case 'this_week':
                        // filteredData = [({
                        //     label: `${begin.format('D dd')} - ${end.format('D dd')}`,
                        //     value: items.reduce((last, item) => last + Number(item.amount), 0)
                        // })];

                        filteredData = filteredData.map((dataset) => {
                            return {
                                label: `${(new Date(dataset.end_date)).format('D dd')}`,
                                value: dataset.data.reduce((last, item) => last + Number(item.amount), 0),
                                dataset
                            };
                        });
                        break;
                    case 'this_month':
                        filteredData = filteredData.map((dataset) => {
                            return {
                                label: `${(new Date(dataset.start_date)).format('dd/m')} - ${(new Date(dataset.end_date)).format('dd/m')}`,
                                value: dataset.data.reduce((last, item) => last + Number(item.amount), 0),
                                dataset
                            };
                        });
                        break;
                }
                return filteredData;
            },
            range() {
                var start = new Date, end = new Date;

                switch (this.chartRange) {
                    case 'today':
                        start.setDate(start.getDate() - 1);
                        start = start.format('Y-mm-dd H:i:s');
                        end = end.format('Y-mm-dd H:i:s');
                        break;
                    case 'this_week':
                        start.setDate(start.getDate() - 7);
                        start = start.format('Y-mm-dd H:i:s');
                        end = end.format('Y-mm-dd H:i:s');
                        break;
                    case 'this_month':
                        start.setDate(start.getDate() - 30);
                        start = start.format('Y-mm-dd H:i:s');
                        end = end.format('Y-mm-dd H:i:s');
                        break;
                }
                
                return `start_date=${start}&end_date=${end}`;
            },
        },
        async beforeMount() {
            this.getTransactions();
            this.getTransactionSummary();
            this.getTopPayments();
        },
        watch: {
            chartRange() {
                this.getTransactionSummary();
            }
        },
        methods: {
            // AJAX Requests
            async getTransactions() {
                this.transactions.loading = true;
                await this.$get({
                    url: `${this.$baseurl}/companies/payments/${this.user.company_id}`,
                    headers: this.headers,
                    success: (response) => {
                        this.transactions.data = response.data.payments;
                    },
                    error: (error) => {
                        console.log(error);
                    }
                });
                this.transactions.loading = false;
            },
            async getTransactionSummary() {
                this.transactionSummary.loading = true;
                await this.$get({
                    url: `${this.$baseurl}/companies/payments/summary/${this.user.company_id}?range=${this.chartRange}`,
                    headers: this.headers,
                    success: (response) => {
                        this.transactionSummary.data = response.data.data;
                    },
                    error: (error) => {
                        console.log(error);
                    }
                });
                this.transactionSummary.loading = false;
            },
            async getTopPayments() {
                this.topTransactions.loading = true;
                await this.$get({
                    url: `${this.$baseurl}/companies/payments/top/${this.user.company_id}`,
                    headers: this.headers,
                    success: (response) => {
                        console.log(response)
                        // this.company = response.data.company;
                        this.topTransactions.data = response.data.payments;
                    },
                    error: (error) => {
                        // this.loanForm.error = error;
                        console.log(error);
                    }
                });
                this.topTransactions.loading = false;

            },

            // Mutations
            setRange(value) {
                console.log(value);
            },
            viewReceipt(payment) {
                // this.receipt = payment.receipt;
                this.$router.push({ name: 'expenses', query: { paymentId: payment.id } });
            },


            // Callbacks
            gridXLabelFormat(value) {
                return `₦${this.$options.filters.currency(value, 1)}`;
            },
            valueFormat(column) {
                return `₦ ${this.$options.filters.currency(column.value)}`;
            },
            tooltipFormat(column) {
                var label;

                switch (this.chartRange) {
                    case 'this_week':
                        label = `
                            <div>${(new Date(column.dataset.end_date)).format('D dd M')}</div>
                        `;
                        break;
                    default:
                        label = `
                            <div>From: ${(new Date(column.dataset.start_date)).format('D dd M')}</div>
                            <div>To: ${(new Date(column.dataset.end_date)).format('D dd M')}</div>
                        `;
                        break;
                }

                return `
                    <div class="text-left text-sm font-light text-gray-700">
                        <div class=" mb-2">
                            ${label}
                            <div>Transactions: ${column.dataset.data.length}</div>
                        </div>
                        <div class="">Total Amount: ₦ ${this.$options.filters.currency(column.value)}</div>
                    </div>
                `;
            }
        }
    }
</script>
